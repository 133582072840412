document.querySelectorAll("[data-derived-from]").forEach(total => {
  let { derivedFrom, factor } = total.dataset
  if (factor) {
    factor = parseInt(factor, 10)
  }

  const inputs = document.querySelectorAll(derivedFrom.split(","))
  const recalculate = () => {
    let sum = 0
    inputs.forEach(input => {
      if (input.value.trim() != "") {
        sum += parseInt(input.value, 10)
      }
    })
    if (factor) {
      sum = sum * factor
    }

    total.value = sum
    total.dispatchEvent(new InputEvent("input"))
  }

  inputs.forEach(input => {
    input.addEventListener("input", recalculate)
  })
})
