import "./Crop"
import "./DateInput"
import "./DateRange"
import "./DerivedFrom"
import "./Dropdown"
import "./TimezoneInput"
import "./TokenInput"
import "./PhoneInput"

import { watchDependents } from "./Dependent"
watchDependents(document.body)

export { watchDependents }
