import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fields", "h2a"]

  connect() {
    this.toggle() // Initialize the visibility based on the current state of h2a
    this.h2aTarget.addEventListener("change", this.toggle.bind(this)) // Bind this to ensure the context
  }

  toggle() {
    const isH2aChecked = this.h2aTarget.checked

    this.fieldsTargets.forEach(field => {
      field.style.display = isH2aChecked ? "" : "none"
    })
  }
}