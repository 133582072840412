import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
    static targets = ["container", "content"]
  
    connect() {
      this.loadSelectedValue(); // Load selected value on connect
      document.addEventListener('click', this.handleClickOutside.bind(this)); // Add click event listener
    }

    disconnect() {
      document.removeEventListener('click', this.handleClickOutside.bind(this)); // Clean up the listener
    }
  
    toggle() {
      this.contentTarget.classList.toggle('show');
    }
  
    select(event) {
      const selectedValue = event.currentTarget.dataset.value; // Access the data-value attribute
      if (!selectedValue) {
        console.error('Selected value is undefined');
        return; // Prevent further action if value is undefined
      }
  
      this.updateButtonText(selectedValue);
      this.updateHiddenInput(selectedValue); // Update hidden input value
      this.contentTarget.classList.remove('show'); // Hide dropdown after selection
      this.submitForm(); // Submit the form here
    }
  
    loadSelectedValue() {
      const hiddenInput = document.getElementById('filter_by');
      const storedValue = hiddenInput.value; // Get the value from the hidden input
      if (storedValue) {
        this.updateButtonText(storedValue);
        this.updateHiddenInput(storedValue);
      }
    }
  
    updateButtonText(value) {
      const button = this.containerTarget.querySelector('.dropdown-button');
      const selectedText = this.getTextFromValue(value);
      button.innerHTML = selectedText; // Update button text
    }
  
    updateHiddenInput(value) {
      const hiddenInput = document.getElementById('filter_by');
      hiddenInput.value = value; // Set the value of the hidden input
    }
  
    getTextFromValue(value) {
      const options = this.containerTarget.querySelectorAll('.dropdown-item');
      for (const option of options) {
        if (option.dataset.value === value) {
          return option.innerHTML; // Return the text for the selected value
        }
      }
      return 'Select'; // Default text if not found
    }
  
    submitForm() {
      const form = this.element.closest('form');
      form.submit(); // Submit the form
    }

    handleClickOutside(event) {
      const isClickInside = this.containerTarget.contains(event.target);
      if (!isClickInside) {
        this.contentTarget.classList.remove('show'); // Hide dropdown if click is outside
      }
    }
  }