import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["city", "state", "zip"]

  connect() {
    this.getCityState()
  }

  getCityState() {
    document.addEventListener("DOMContentLoaded", ()=> {
        const zipInput = this.zipTarget
        const cityInput = this.cityTarget
        const stateInput = this.stateTarget
        if (zipInput) {
          zipInput.addEventListener("input", async function() {
            const zipCode = zipInput.value;
            if (zipCode !== "") {
              try {
               
                const response = await fetch(`/fetchCityState/${zipCode}`);
                const data = await response.json();
                
                
                if (data.city) {
                  cityInput.value = data.city;
                }
                if (data.state) {
                  stateInput.value = data.state;
                }
                
              } catch (error) {
                console.log("Error fetching city and state:", error);
              }
            }
          });
        }
      });
  }

}