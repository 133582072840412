import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["labelField", "select"]

  connect() {
    this.update()
  }

  update() {
    const selected = this.selectTarget.querySelector("option:checked")
    this.labelFieldTarget.value = selected.label || selected.innerHTML
  }
}
