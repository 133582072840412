import intlTelInput from 'intl-tel-input';
import intlTelInputUtils from 'intl-tel-input/build/js/utils';
import Cleave from "cleave.js";

// Function to initialize intlTelInput and Cleave for a phone input element
function initializePhoneInput(phoneInput) {
  if (phoneInput) {
    let mycleaveInstance = null; 
    let currentMaskFormat = null;
    const iti = intlTelInput(phoneInput, {
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
      initialCountry: 'us',
      separateDialCode: false,
    });
    const classNameToRemove = "iti";
    const classes = phoneInput.parentNode.className.split(" ");
    if (classes.includes(classNameToRemove)) {
      phoneInput.parentNode.classList.remove(classNameToRemove);
    }
    
    // Function to format and update the phone number field
    function formatAndUpdatePhoneNumber() {
      const selectedCountryData = iti.getSelectedCountryData();
      const selectedCountryCode = selectedCountryData.iso2;
      const phoneNumber = iti.getNumber();
      const dial = selectedCountryData.dialCode;
      const maskFormat = getMaskFormat(selectedCountryCode);
      // Destroy the old Cleave instance if it exists
      phoneInput.value = phoneNumber;
      // Apply the mask format to the input value
      applyMask(phoneNumber, selectedCountryCode, dial);
      currentMaskFormat = maskFormat;
    }

    function getMaskFormat(countryCode) {
      // You can implement your logic here to determine the mask format
      // based on the country code. For example, you can use a lookup table
      // or any other method to define the mask format for each country code.
      // This is just a sample implementation:
      if (countryCode === "us") {
        return "us";
      } else if (countryCode === "mx") {
        return "mx";
      } else if (countryCode === "in") {
        return "in";
      } else {
        // Default mask format for other countries
        return "default";
      }
    }

    // Helper function to apply the mask format to the phone number
    function applyMask(phoneNumber, maskFormat, dialCode) {
      let blocks, delimiters;

      if (maskFormat === "us") {
        blocks = [2, 3, 3, 4];
        delimiters = [" (", ") ", "-"];
      } else if (maskFormat === "mx") {
        blocks = [3, 2, 4, 4];
        delimiters = [" ", " ", " "];
      } else if (maskFormat === "in") {
        blocks = [3, 5, 5];
        delimiters = [" ", " "];
      } else {
        var number = 0;
        if (dialCode) {
          number = dialCode.length;
        }
        blocks = [number + 1, 5, 5];
        delimiters = [" ", " "];
      }
      if (mycleaveInstance) {
        mycleaveInstance.destroy();
        mycleaveInstance = null;
      } 
      // Create a new Cleave instance
      mycleaveInstance = new Cleave(phoneInput, {
        blocks: blocks,
        delimiters: delimiters,
        delimiterLazyShow: true,
        numericOnly: false,
      });
    }

    // Format the phone number initially
    formatAndUpdatePhoneNumber();
    // Listen for input changes on the phone number field
    phoneInput.addEventListener('input', formatAndUpdatePhoneNumber);

    phoneInput.addEventListener("countrychange", function() {
      // do something with iti.getSelectedCountryData()
      formatAndUpdatePhoneNumber();
    });
  }
}

// Initialize phone input fields with [data-phone] attribute
document.querySelectorAll("[data-phone]").forEach(initializePhoneInput);


export function initializePhoneInputIfNeeded(input) {
    initializePhoneInput(input);
}