import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['usicNumber', 'i9Admission'];

  connect() {
    this.usicNumberTarget.addEventListener('input', this.validateUsic.bind(this));
    this.i9AdmissionTarget.addEventListener('input', this.validateI9Admission.bind(this));
  }

  validateUsic(event) {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9-]/g, '').substring(0, 14);
    event.target.value = sanitizedValue;
  }

  validateI9Admission(event) {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9-]/g, '').substring(0, 11);
    event.target.value = sanitizedValue;
  }
}