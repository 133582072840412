import { Controller } from "@hotwired/stimulus"
import { watchDependents } from "../components"
import { initializePhoneInputIfNeeded } from "../components/PhoneInput";


export default class extends Controller {
  static targets = ["button"]
  
  connect() {
    const { name } = this.element.dataset
    const index = this.index()
    this.element.querySelectorAll("input, select, textarea").forEach(input => {
      
      const { attribute } = input.dataset
      let newName = `${name}[${index}]`
      if (attribute) {
        newName = `${newName}${attribute}`
      }
      const { name: oldName } = input
      let originalCheckName = "wrongName"
      let topNewReplaceName = input.parentElement.parentElement.parentElement.parentElement.parentElement.firstChild.name
      if (topNewReplaceName) {
        if (name.includes("FieldPickList")) {
          originalCheckName = name.replace("FieldPickList", "")
            if (topNewReplaceName.includes("FieldID")) {
              let newAddName = topNewReplaceName.replace("FieldID", "")
              newName = newName.replace(originalCheckName, newAddName)
            }
        }
      }
      input.name = newName
      if (input.type && input.type.toLowerCase() !== "hidden") {
        const oldID = input.id
        const newID = newName
          .toLowerCase()
          .replace(/[^a-z0-9]/gi, " ")
          .trim()
          .replace(/\s+/gi, "_")
        input.id = newID
        this.element
          .querySelectorAll(`label[for="${oldID}"]`)
          .forEach(label => label.setAttribute("for", newID))

        if (index === 0) {
          document
            .querySelectorAll(`label.multiple[for="${oldID}"]`)
            .forEach(label => label.setAttribute("for", newID))
        }
      }

      if (newName != oldName) {
        this.element.querySelectorAll(`[data-dependent-on*="${oldName}"]`).forEach(dependency => {
          dependency.dataset.dependentOn = dependency.dataset.dependentOn.replace(oldName, newName)
        })
      }
    })
    watchDependents(this.element)
  }

  index() {
    return Array.prototype.indexOf.call(this.siblings(), this.element)
  }

  siblings() {
    return this.element.parentNode.childNodes
  }

  toggle(event) {
    event.preventDefault()
    const siblings = this.siblings()
    const currentIndex = this.index()
    const totalSiblings = siblings.length
    if (currentIndex < totalSiblings - 1) {
      // Remove the element and update the remaining siblings' names
      this.element.remove()
      this.renameFields(siblings, currentIndex);
    } else {
      const newField = this.element.cloneNode(true)
      newField.querySelectorAll("input:not([type='hidden'])").forEach(input => {
        const type = input.type.toLowerCase()
        if (type === "checkbox" || type === "radio") {
          input.checked = false
        } else {
          input.value = ""
        }
        if (type == "tel") {
          initializePhoneInputIfNeeded(input);
        }
  
      })

       
      this.element.parentNode.appendChild(newField)
      // this.updateSiblingNames(siblings, currentIndex)
      this.buttonTarget.querySelector(".bi").className = "bi bi-trash"
    }
  }

  renameFields(siblings, startIndex) {
    
    siblings.forEach((sibling, index) => {
      // if (index > (startIndex)) {
        const fields = sibling.querySelectorAll("input, select, textarea");
  
        fields.forEach(input => {
          const { name } = input;
          let newName = name.replace(/\[(\d+)\]/, `[${index}]`);
          let originalCheckName = "wrongName"
          let topNewReplaceName = input.parentElement.parentElement.parentElement.parentElement.parentElement.firstChild.name
          
          if (topNewReplaceName) {
            if (name.includes("FieldPickList")) {
              originalCheckName = name.replace("FieldPickList", "")
                if (topNewReplaceName.includes("FieldID")) {
                  let newAddName = topNewReplaceName.replace("FieldID", "")
                  newName = newName.replace(originalCheckName, newAddName)
                }
            }
          }
          input.name = newName;
          if (input.type && input.type.toLowerCase() !== "hidden") {
            const oldID = input.id
            const newID = newName
              .toLowerCase()
              .replace(/[^a-z0-9]/gi, " ")
              .trim()
              .replace(/\s+/gi, "_")
            input.id = newID
            sibling
              .querySelectorAll(`label[for="${oldID}"]`)
              .forEach(label => label.setAttribute("for", newID))
    
            if (index === 0) {
              document
                .querySelectorAll(`label.multiple[for="${oldID}"]`)
                .forEach(label => label.setAttribute("for", newID))
            }
          }
          // Update dependent fields
          sibling.querySelectorAll(`[data-dependent-on*="${name}"]`).forEach(dependency => {
            const dependentOn = dependency.dataset.dependentOn
            const newDependentOn = dependentOn.replace(name, newName)
            dependency.dataset.dependentOn = newDependentOn
          })

        });

      });
    watchDependents(this.element)
  }

}
