import Croppr from "croppr"

document.querySelectorAll("[data-crop]").forEach(img => {
  const input = document.querySelector(img.dataset.crop)
  const _ = new Croppr(img, {
    onCropEnd(data) {
      if (input) {
        input.value = JSON.stringify(data)
      }
    },
  })
})
