import Cleave from "cleave.js"

document.querySelectorAll("[data-date]").forEach(input => {
  const _ = new Cleave(input, {
    date: true,
    delimiter: "/",
    datePattern: ["m", "d", "Y"],
    delimiterLazyShow: false,
  })
})
