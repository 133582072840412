import "daterangepicker"

document.querySelectorAll("[data-date-range]").forEach(container => {
  let [startDate, endDate] = container.dataset.dateRange.split(",")
  startDate = document.querySelector(startDate)
  endDate = document.querySelector(endDate)

  const picker = $(container)
    .daterangepicker({
      autoApply: true,
      alwaysShowCalendars: true,
      autoUpdateInput: false,
      endDate: moment(),
      linkedCalendars: true,
      maxSpan: {
        months: 31,
      },
      parentEl: container,
      ranges: {
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      },
      startDate: moment().subtract(6, "days"),
    })
    .on("apply.daterangepicker", (_, picker) => {
      picker.updateCalendars()
      picker.updateMonthsInView()

      if (startDate) {
        startDate.value = picker.startDate.format("YYYY-MM-DD")
      }

      if (endDate) {
        endDate.value = picker.endDate.format("YYYY-MM-DD")
      }
    })
  picker.data("daterangepicker").show()
})
