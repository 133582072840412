import { Controller } from "@hotwired/stimulus"
import flatpickr from 'flatpickr';
// import "flatpickr/dist/flatpickr.css";

export default class extends Controller {

  connect() {
    flatpickr(this.element, {
      maxDate: new Date().fp_incr(2) // Set minimum date to tomorrow
    });
  }
}