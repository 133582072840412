import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nameSelect"];

  

  initialize() {
    // Parse the JSON string into a JavaScript object
    const jsonObject = JSON.parse(this.data.get("identity-authorization-titles"));
    this.identityAuthorizationTitlesValue = Object.values(jsonObject);
    
    const jsonObject1 = JSON.parse(this.data.get("identity-titles"));
    this.identityTitlesValue = Object.values(jsonObject1);

    const jsonObject2 = JSON.parse(this.data.get("employment-authorization-titles"));
    this.employmentAuthorizationTitlesValue = Object.values(jsonObject2);

    
  }

  connect() {
    this.updateNameOptions(); // Initialize the name field options
  }

  updateNameOptions() {


    const issuingAuthority = document.getElementById('id_document_type').value

    // const issuingAuthority = this.element.querySelector(
    //   'select[name="document[issuing_authority]"]'
    // ).value;

    const nameSelect = document.getElementById('id_document_name')
    
    nameSelect.innerHTML = ""; // Clear existing options

    const optionsMap = {
      identityIdentityAuthorization: this.identityAuthorizationTitlesValue,
      identity: this.identityTitlesValue,
      employmentAuthorization: this.employmentAuthorizationTitlesValue,
    };

    const options = optionsMap[issuingAuthority] || [];
    options.forEach((option) => {
      const optionElement = document.createElement("option");
      optionElement.value = option;
      optionElement.text = option;
      nameSelect.appendChild(optionElement);
    });
    const selected = this.data.get('selected-name');
    if (options.includes(selected)) {
      nameSelect.value = selected
    }
    // Create a new event
   const event = new Event("change", {
      bubbles: true, // This makes the event bubble up through the DOM tree
      cancelable: true, // This allows the event to be canceled
   });

    // Trigger the change event on the input element
    nameSelect.dispatchEvent(event);
  }
}