import Cleave from "cleave.js"

document.querySelectorAll("[data-token]").forEach(input => {
  const _ = new Cleave(input, {
    blocks: Array.from(JSON.parse(input.dataset.token)),
    delimiters: Array.from(JSON.parse(input.dataset.tokenDelimiters || '["-"]')),
    delimiterLazyShow: false,
    numericOnly: !!input.dataset.tokenNumeric,
  })
})
