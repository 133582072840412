import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    initialize() {
        
            this.listenEmployeeInput()
    }

    employeeForceManually() {
        const employeeForceManuallyInput = document.querySelector('#employee_force_manually');
        return employeeForceManuallyInput && employeeForceManuallyInput.checked;
    }

  listenEmployeeInput() {
    const input = document.querySelector('#employee_tax_id');
    input.addEventListener('input', (event) => {
      this.timer = setTimeout(() => {
        const ssnNumber = input.value;
        if (this.employeeForceManually()) {
            return
        }
        if (ssnNumber !== "") {
            let replacedSSN = ssnNumber.replace(/-/g, "");
            if (replacedSSN.length > 8) {
              const payrollInput = document.querySelector('#employee_payroll_id');

                fetch(`/fetchEmployeeNumber/${replacedSSN}?payroll=${payrollInput.value}`)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error('Network response was not ok.');
                    })
                    .then(data => {
                        // Handle the response data here
                        if (data.payroll_id) {
                            const payrollInput = document.querySelector('#employee_payroll_id');
                            payrollInput.value = data.payroll_id;
                        }
                    })
                    .catch(error => {
                        console.error('There was a problem with the fetch operation:', error);
                    });
            }
        }
      }, 1);
      input.addEventListener('keyup', () => {
        clearTimeout(this.timer);
      });
    });
  }
  
}